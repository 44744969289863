import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import LogoMoneycorp from '../../assets/image/moneycorp.svg';
import { AuthContext } from "../../contexts/auth";
import { AvatarStyled, UserNameStyled } from "../../pages/_layouts/drawer/style";
import { Button } from '../Button';
import { LanguageSelect } from "../LanguageSelect";
import { AppBarStyled } from "./styles";

export const UserInfo = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    gap: .5rem;
`;

export function Header() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    var name = user?.value?.name ?? "";
    var result = name.split(" ");

    return (
        <AppBarStyled
            position="sticky"
        >
            <Grid
                container
                spacing={2}
            >
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                >
                    <a
                        href="/"
                    >
                        <img
                            src={LogoMoneycorp}
                            alt="Moneycorp banco de câmbio"
                        />
                    </a>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={4}
                    md={4}
                    lg={4}
                    xl={4}
                >
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            <LanguageSelect />
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                        >
                            {user.value ? (
                                <UserInfo>
                                    <UserNameStyled>
                                        {user.value.name}
                                    </UserNameStyled>
                                    <AvatarStyled>
                                        {(result[0]).substr(0, 1)}
                                    </AvatarStyled>
                                </UserInfo>
                            ) : (
                                <Button
                                    label={t("text.enter")}
                                    variant="contained"
                                    component={Link}
                                    to="/"
                                    fullWidth
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </AppBarStyled >
    );
}