import {
    Autocomplete,
} from '@mui/material';
import { Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { TextField } from "../TextField";
import React from 'react';

type Country = {
    id:string ;
    name:string ;
    acronym:string;
}

interface CRMCountryOptionsProps {
    control: any ; 
    name: string ; 
    countries: Array<Country> ; 
    error:any ; 
    countryChanged?:() => void ;
}


export function CRMCountryOptions({ control, name, countries, error, countryChanged }: CRMCountryOptionsProps) {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                const { value, onChange } = field;
                return (
                    <Autocomplete
                        value={
                            value
                                ? countries.find((option) => {
                                    return value === option.acronym;
                                }) ?? null
                                : null
                        }
                        onChange={(event, newValue) => {
                            onChange(newValue ? newValue.acronym : null);
                            if(countryChanged) countryChanged();
                        }}
                        getOptionLabel={(country) => country.name}
                        options={countries}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={field.ref}
                                error={error}
                                label={t("text.country")}
                                variant="outlined"
                            />
                        )}
                    />
                )
            }}
        />
    );
}